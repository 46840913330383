
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
// .Type {}

.display3 {
  font-size: spacing(3.75); // 30
  line-height: spacing(4); // 32
  font-weight: 800;
  font-family: $font-heading;

  @include mq($breakpoint-tablet) {
    font-size: spacing(4.5); // 36
    line-height: spacing(5); // 40
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(6); // 48
    line-height: spacing(8); // 64
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(9); // 72
    line-height: spacing(9); // 72
  }
}

.display2 {
  font-size: spacing(3.75); // 30
  line-height: spacing(4); // 32
  font-weight: 800;
  font-family: $font-heading;

  @include mq($breakpoint-tablet) {
    font-size: spacing(4); // 36
    line-height: spacing(5); // 40
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(5); // 40
    line-height: spacing(6); // 48
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(6); // 48
    line-height: spacing(8); // 64
  }
}

.display1 {
  font-size: spacing(3); // 24
  line-height: spacing(4); // 32
  font-weight: 800;
  font-family: $font-heading;

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(4); // 32
    line-height: spacing(5); // 40
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(5); // 40
    line-height: spacing(6); // 48
  }
}

.display0 {
  font-family: $font-heading;
  font-size: spacing(2.25); // 18
  line-height: spacing(3); // 24
  font-weight: 500;

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(3); // 24
    line-height: spacing(4); // 32
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(4); //  32
    line-height: spacing(5); // 40
  }
}

.title-large {
  font-size: spacing(3.75); // 32
  line-height: spacing(4.5); // 40
  font-weight: 700;

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(5); // 40
    line-height: spacing(6); // 48
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(7); // 56
    line-height: spacing(8); // 64
  }
}

.title {
  font-size: spacing(3); // 24
  line-height: spacing(4); // 32
  font-weight: 700;

  @include mq($breakpoint-desktop) {
    font-size: spacing(4); // 32
    line-height: spacing(5); // 40
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(5); // 40
    line-height: spacing(6); // 48
  }
}

.title-small {
  font-size: spacing(2.25); // 18
  line-height: spacing(3); // 24
  font-weight: 700;

  @include mq($breakpoint-tabletLandscape) {
    font-size: spacing(2.5); // 20
    line-height: spacing(3); // 24
  }

  @include mq($breakpoint-desktop + 100) {
    font-size: spacing(3); // 24
    line-height: spacing(4); // 32
  }

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(4); // 32
    line-height: spacing(5); // 40
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(5); // 40
    line-height: spacing(6); // 48
  }
}

.subtitle {
  font-size: spacing(2.25); // 18
  line-height: spacing(3); // 24
  font-weight: 500;

  @include mq($breakpoint-desktopMedium) {
    font-size: spacing(3); // 24
    line-height: spacing(4); // 32
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(4); //  32
    line-height: spacing(5); // 40
  }
}

.base-large {
  font-size: spacing(2.25); // 18
  line-height: spacing(3); // 24

  @include mq($breakpoint-desktop) {
    font-size: spacing(3); // 24
    line-height: spacing(4); // 32
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(3.75); // 30
    line-height: spacing(5); // 40
  }
}

.base {
  font-size: spacing(2); // 16
  line-height: spacing(3); // 24

  @include mq($breakpoint-desktop) {
    font-size: spacing(2.25); // 18
    line-height: spacing(3); // 24
  }

  @include mq($breakpoint-desktopLarge) {
    font-size: spacing(3); // 24
    line-height: spacing(4); // 32
  }
}

.small {
  font-size: spacing(1.75); // 14
  line-height: spacing(2); // 16
}

// Modifiers

.tight {
  line-height: 1;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.upperFirst {
  &::first-letter {
    text-transform: uppercase;
  }
}

$color-list: (
  'color-green': $color-highlight-green,
  'color-yellow': $color-highlight-yellow,
  'color-orange': $color-highlight-orange,
  'color-pink': $color-highlight-pink,
  'color-red': $color-highlight-red,
  'color-teal': $color-primary-teal,
  'color-black': $color-primary-black,
  'color-white': $color-primary-white,
  'color-grey': $color-primary-grey
);

@each $key, $value in $color-list {
  .#{$key} {
    color: $value;
  }
}
